// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.footer-container {
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .footer-container {
    position: relative;
    clear: both;

    margin-top: 300px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Layout/Router.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;;IAEX,iBAAiB;EACnB;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  justify-content: space-between;\n}\n\n.footer-container {\n  width: 100%;\n  position: relative;\n}\n\n@media (max-width: 768px) {\n  .footer-container {\n    position: relative;\n    clear: both;\n\n    margin-top: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
