import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFoundPage = () => {
  let navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className="not-found-container">
      <h1>Oops!</h1>
      <h2>404 - PAGE NOT FOUND</h2>
      <p>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>
      <button onClick={goToHome}>Return Home</button>
    </div>
  );
};

export default NotFoundPage;
