import React from "react";
import SideBar from "./SideBar";
import DocumentUpload from "../PDFDocument/DocumentUpload";
import "./DocumentView.css";

export default function DocumentPage() {
  return (
    <div className="flex-container">
      <div className="sidebar-container">
        <SideBar />
      </div>
      <div className="w-100 center-content">
        <DocumentUpload />
      </div>
    </div>
  );
}
