import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PDFQuestion.css";
import { MDBSpinner } from "mdb-react-ui-kit";
import Toast from "react-bootstrap/Toast";
import { useParams } from "react-router-dom";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";

// const CustomTypingEffect = ({ text = "", typingDelay = 0 }) => {
//   const [displayedText, setDisplayedText] = useState("");

//   useEffect(() => {
//     let charIndex = 0;
//     let timerId;

//     const typeChar = () => {
//       if (charIndex < text.length) {
//         setDisplayedText((prev) => prev + text[charIndex]);
//         charIndex += 1;
//         timerId = setTimeout(typeChar, typingDelay);
//       }
//     };

//     typeChar();

//     return () => clearTimeout(timerId);
//   }, [text, typingDelay]);

//   return <p>{displayedText}</p>;
// };

function FormatResponseText({ text }) {
  // This function will replace markdown with HTML tags
  const markdownToHTML = (str) => {
    // Replace "###" headers with <h3> tags
    str = str.replace(/###\s(.+)/g, (match, p1) => `<h3>${p1}</h3>`);
    // Replace bold "**" with <strong> tags
    str = str.replace(
      /\*\*(.+?)\*\*/g,
      (match, p1) => `<strong>${p1}</strong>`
    );
    return str;
  };

  // Split the text by new lines to identify points and headers.
  const points = text.split(/\n/);

  return (
    <div className="formatted-response">
      {points.map((point, index) => {
        // Check for markdown headers or lists
        if (/^\d+\.\s|•\s|\*\s|-\s/.test(point)) {
          // It's a list item
          return (
            <li
              key={index}
              className="response-point"
              dangerouslySetInnerHTML={{
                __html: markdownToHTML(
                  point.replace(/^\d+\.\s|•\s|\*\s|-\s/, "")
                ),
              }}
            />
          );
        } else if (/^###/.test(point)) {
          // It's a header
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: markdownToHTML(point) }}
            />
          );
        } else {
          // It's a regular paragraph
          return (
            <p
              key={index}
              className="response-text"
              dangerouslySetInnerHTML={{ __html: markdownToHTML(point) }}
            />
          );
        }
      })}
    </div>
  );
}

function PDFQuestion({ fileBlob }) {
  const { docId } = useParams();

  const [question, setQuestion] = useState("");
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [progress, setProgress] = useState(100); // Progress in percentage
  const [prompts, setPrompts] = useState([
    "What is the main topic of this document?",
    "Could you summarize the key points?",
    "Are there any action items mentioned?",
  ]);
  const handlePromptClick = (prompt) => {
    setQuestion(prompt);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");

    if (!fileBlob) {
      displayToast("Please upload a PDF file.");
      return;
    }

    if (question.trim().length < 10) {
      displayToast("Your question must be at least 10 characters long.");
      return;
    }
    const formData = new FormData();
    formData.append("pdf", fileBlob); // Use the blob here
    formData.append("question", question);

    setLoading(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/ask`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const answerTexts = data.answer
        .flatMap((group) =>
          group.map((item) => (item.type === "text" ? item.text.value : ""))
        )
        .join(" "); // Join all text values with a space

      // Instead of setting responses as a string, we'll store it as JSX
      const formattedAnswer = <FormatResponseText text={answerTexts} />;

      setResponses([{ question, answer: formattedAnswer }, ...responses]);
      setQuestion("");
      setPrompts([]);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 429) {
        // Here we customize the message for a 429 status code
        displayToast(
          "The file size exceeds the maximum limit. Please upload a smaller file."
        );
      } else {
        displayToast(
          "The file size exceeds the maximum limit. Please upload a smaller file and try again!"
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Check if Enter key is pressed without shift key
      event.preventDefault(); // Prevent the default action to insert a newline
      handleSubmit(event); // Call the handleSubmit function
    }
  };

  const displayToast = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };
  // useEffect(() => {
  //   console.log(docId);
  // }, [file]);

  useEffect(() => {
    let intervalId;

    if (showToast) {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress <= 0) {
            clearInterval(intervalId);
            return 0;
          }
          return oldProgress - 100 / (5000 / 100); // Decrease progress based on delay
        });
      }, 100); // Update progress every 100ms
    } else {
      setProgress(100); // Reset progress when toast is not showing
    }

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
    };
  }, [showToast]);
  return (
    <div className="pdf-center-container-demo">
      {showToast && (
        <Toast
          className="toast-top"
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
          <div className="toast-progress-container">
            <div
              className="toast-progress"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </Toast>
      )}
      <div className="pdf-qa-box">
        <div className="chat-container">
          {" "}
          {responses.length > 0 && (
            <div
              className="chat-message"
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                overflowY: "scroll",
              }}
            >
              {responses.map((resp, index) => (
                <div key={index}>
                  <div className="message question">
                    <i className="fa fa-user icon"></i>
                    <p>{resp.question}</p>
                  </div>
                  <div className="message answer">
                    <i className="fa fa-book icon"></i>
                    <p>{resp.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="pdf-input-container">
          {errorMessage && <p className="pdf-error-message">{errorMessage}</p>}
          <div className="pdf-prompts-container">
            {prompts.map((prompt, index) => (
              <div
                key={index}
                className="pdf-prompt"
                onClick={() => handlePromptClick(prompt)}
              >
                {prompt}
              </div>
            ))}
          </div>
          <div className="pdf-textarea-container">
            <textarea
              className="pdf-question-textarea"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your question here..."
              disabled={loading} // Disable textarea when loading
            />
            {/* Conditional rendering for the spinner and the Ask button */}
            {loading ? (
              <MDBSpinner
                style={{
                  color: "#677de1",
                  marginBottom: "5px",
                  backgroundColor: "transparent",
                }}
              />
            ) : (
              <button className="pdf-button" type="submit">
                Ask
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default PDFQuestion;
