import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Define your fetchData function before using it in useEffect
    const fetchUserDetails = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        if (!token) {
            console.log('No token found, user not logged in');
            setLoading(false);
            return;
        }

        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/details`, config);
            if (userResponse.status === 200) {
                const user = userResponse.data.user;

                const paymentStatusResponse = await axios.get(`${process.env.REACT_APP_API_URL}/is-paying-user`, config);
                if (paymentStatusResponse.status === 200) {
                    setUser({ 
                        ...user, 
                        isPayingUser: paymentStatusResponse.data.isPayingUser, 
                        subscriptionDetails: paymentStatusResponse.data.subscriptionDetails 
                    });
                } else {
                    console.log('Failed to check payment status');
                    setUser(user);
                }
            }
        } catch (error) {
            console.error('Error during authentication or payment status check', error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    };

    // Now use the fetchData function inside useEffect
    useEffect(() => {
        fetchUserDetails();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, loading }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
