// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  text-align: center;
  background-color: #fff; /* Replace with galaxy background */
  color: #000; /* Adjust colors as necessary */
  padding: 50px;
  border-radius: 5px;
  position: relative;
  margin-top: 100px;
  margin-bottom: 200px;
  transform: translateY(0%);
}

.not-found-container h1 {
  font-size: 72px;
  font-weight: bold;
}

.not-found-container h2 {
  font-size: 48px;
}

.not-found-container p {
  margin: 20px 0;
}

.not-found-container button {
  background-color: #677de1;
  color: white;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.not-found-container button:hover {
  background-color: #2980b9;
}
`, "",{"version":3,"sources":["webpack://./src/Layout/NotFound.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB,EAAE,mCAAmC;EAC3D,WAAW,EAAE,+BAA+B;EAC5C,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".not-found-container {\n  text-align: center;\n  background-color: #fff; /* Replace with galaxy background */\n  color: #000; /* Adjust colors as necessary */\n  padding: 50px;\n  border-radius: 5px;\n  position: relative;\n  margin-top: 100px;\n  margin-bottom: 200px;\n  transform: translateY(0%);\n}\n\n.not-found-container h1 {\n  font-size: 72px;\n  font-weight: bold;\n}\n\n.not-found-container h2 {\n  font-size: 48px;\n}\n\n.not-found-container p {\n  margin: 20px 0;\n}\n\n.not-found-container button {\n  background-color: #677de1;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  text-transform: uppercase;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.not-found-container button:hover {\n  background-color: #2980b9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
