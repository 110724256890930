// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TermsOfService.css */
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.centered-content section {
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-top: 50px;
}

.centered-content h2:first-of-type {
  margin-top: 20px;
}

.centered-content section:last-child {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Layout/TermsOfService.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* TermsOfService.css */\n.centered-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  text-align: center;\n  width: 70%;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 20px;\n}\n\n.centered-content section {\n  margin-bottom: 20px;\n  padding: 10px;\n  border-bottom: 1px solid #ccc;\n  margin-top: 50px;\n}\n\n.centered-content h2:first-of-type {\n  margin-top: 20px;\n}\n\n.centered-content section:last-child {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
