import React, { useState, useEffect } from "react";
import "./DemoSection.css";
import SignUpModal from "../Login/LoginModal/SignUpModal";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faBook, faFile } from "@fortawesome/free-solid-svg-icons"; // Example icons

const DemoSection = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    setShowModal(true);
  };

  const navigateToDemo = () => {
    navigate("/demo"); // Navigate to the DocumentUpload route
  };

  return (
    <div className="demo-section" id="demo">
      <div>
        <h2>See How Easy It Is</h2>
        <p className="demo-description">
          Discover the ease of managing your documents. Upload a file, and let
          the magic happen. No hassle, just results.
          <br />
          <b> Supported Formats: PDF, .PPTX, .DOCX</b>
        </p>

        <div onClick={navigateToDemo}>{/* <FileDropzone /> */}</div>
        <div className="cards-container">
          <div className="card" style={{ width: "18rem" }}>
            <div className="card-body">
              <h5 className="card-title">Upload Documents</h5>
              <FontAwesomeIcon icon={faFile} size="lg" /> {/* Example icon */}
              <p className="card-text">
                Easily upload the documents you'd like to chat with.
              </p>
            </div>
          </div>
          <div className="card" style={{ width: "18rem" }}>
            <div className="card-body">
              <h5 className="card-title"> Instant answers</h5>
              <FontAwesomeIcon icon={faBook} size="lg" /> {/* Example icon */}
              <p className="card-text">
                Ask questions, extract information, and summarize documents with
                AI.
              </p>
            </div>
          </div>
        </div>
        <button className="start-trial-button" onClick={openModal}>
          Get Started
        </button>
        <button className="try-demo-button" onClick={navigateToDemo}>
          Try the demo →
        </button>
      </div>
      <SignUpModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default DemoSection;
