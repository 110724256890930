import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import axios from "axios";

function SignUpModal({ show, onClose }) {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mode, setMode] = useState("signup");
  const [errorMessage, setErrorMessage] = useState(""); // State to store error messages

  const toggleMode = () => {
    setMode(mode === "signup" ? "login" : "signup");
    setErrorMessage(""); // Clear any existing error messages
  };

  const handleSignUp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        { displayName, email, password }
      );

      if (response.status === 201) {
        console.log("Switching to login mode"); // Debugging line
        setMode("login");
        setErrorMessage("");
      }
    } catch (error) {
      console.error("Sign up error", error.response);

      if (error.response && error.response.status === 400) {
        setErrorMessage("Email or username is already in use.");
      } else {
        setErrorMessage(
          "An error occurred during sign up. Please try again later."
        );
      }
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        { email, password }
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        window.location.href = "/welcome";
      }
    } catch (error) {
      console.error("Login error", error.response);
      setErrorMessage("Incorrect email or password."); // Set error message on failed login
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (mode === "signup") {
      await handleSignUp();
    } else {
      await handleLogin();
    }
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}

            {mode === "signup" && (
              <Form.Group className="mb-3" controlId="username">
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            {mode === "signup" ? (
              <Button
                style={{ backgroundColor: "#677DE1" }}
                type="submit"
                className="w-100"
              >
                Sign Up
              </Button>
            ) : (
              <Button
                style={{ backgroundColor: "#677DE1" }}
                type="submit"
                className="w-100"
              >
                Login
              </Button>
            )}
          </Modal.Body>
        </Form>
        <Modal.Footer>
          <p style={{ cursor: "pointer", color: "black" }} onClick={toggleMode}>
            {mode === "signup"
              ? "Already have an account? Log In"
              : "Don't have an account? Sign Up"}
          </p>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignUpModal;
