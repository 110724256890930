import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Document, Page } from "react-pdf";
import PDFQuestion from "../AskQuestion/PDFQuestion";
import PDFViewer from "./PDFViewer";
import "./document.css";
import SideBar from "../DocumentView/SideBar";

const DocumentViewer = () => {
  const { docId } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileUrl, setFileUrl] = useState(null); // State to store the PDF file data
  const [pdfBlob, setPdfBlob] = useState(null); // Add a state for the blob

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const token = localStorage.getItem("token");
        const apiUrl = process.env.REACT_APP_API_URL;

        const response = await axios.get(`${apiUrl}/documents/${docId}`, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "arraybuffer",
        });

        // Create a Blob from the binary data received
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Save the blob itself for uploading purposes
        setPdfBlob(blob);

        // Create a URL for the blob for viewing purposes
        const blobUrl = URL.createObjectURL(blob);
        setFileUrl(blobUrl);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (docId) {
      fetchDocument();
    }

    // Clean up the blob URL when the component unmounts or the fileUrl changes
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [docId]); // You should include fileUrl in the dependency array if you're using it in the cleanup function

  return (
    <>
      {" "}
      <div className="flex-container">
        <div className="sidebar-container">
          <SideBar />
        </div>
        <div className="content-container">
          <div className="pdf-viewer-container">
            {fileUrl && (
              <PDFViewer
                fileUrl={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              />
            )}
          </div>
          <div className="question-box-container">
            {fileUrl && <PDFQuestion fileUrl={fileUrl} fileBlob={pdfBlob} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentViewer;
