import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  FaTrashAlt,
  FaPlus,
  FaFileAlt,
  FaArrowLeft,
  FaArrowRight,
  FaUser,
} from "react-icons/fa";
import "./Sidebar.css";
import { useUser } from "../Context/UserContext";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

import Dropdown from "react-bootstrap/Dropdown";

const SideBar = () => {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate(); // Create the navigate function
  const { user, loading } = useUser(); // Use the useUser hook
  const [pageCount, setPageCount] = useState(0);
  const [documentsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);

  // Function to toggle dropdown visibility
  // const toggleDropdown = () => {
  //   setIsDropdownVisible(!isDropdownVisible);
  // };

  const toggleSidebar = () => {
    console.log("Toggling sidebar");
    setSidebarCollapsed(!sidebarCollapsed);
  };

  useEffect(() => {
    // Redirect to login page if not authenticated
    if (!loading && !user) {
      navigate("/login"); // Replace '/login' with your login route
      return;
    }
  });

  const handleStartButtonClick = () => {
    navigate("/pay");
  };

  // const handlePageClick = (data) => {
  //   let selected = data.selected;
  //   setCurrentPage(selected + 1); // react-paginate uses zero indexing for selected
  // };
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      // Code to fetch more documents and update state
    }
  };

  useEffect(() => {
    const fetchUserDocuments = async () => {
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem("token");
        const apiUrl = process.env.REACT_APP_API_URL;

        // Make a GET request to the server to fetch the user's documents
        const response = await axios.get(`${apiUrl}/documents`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Set the documents in state if the response is an array
        if (Array.isArray(response.data)) {
          setDocuments(response.data);
        } else {
          console.error(
            "Expected an array of documents, received:",
            response.data
          );
          setDocuments([]);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        // If there's an error, set the documents to an empty array
        setDocuments([]);
      }
    };

    fetchUserDocuments();
  }, []);

  const handleRowClick = (docId) => {
    setSelectedDocId(docId); // Update the selected document's ID
    navigate(`/upload/${docId}`); // Navigate to /upload/:docId
  };

  // Filter the documents based on the search term
  const filteredDocuments = searchTerm
    ? documents.filter((doc) =>
        doc.fileName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : documents;
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );

  useEffect(() => {
    setPageCount(Math.ceil(filteredDocuments.length / documentsPerPage));
  }, [filteredDocuments, documentsPerPage]);

  const handleDeleteDocument = async (docId) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = process.env.REACT_APP_API_URL;

      await axios.delete(`${apiUrl}/document/${docId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setDocuments(documents.filter((doc) => doc._id !== docId));
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };
  const handleNewDocument = () => {
    window.location.href = "/document";
  };
  const logout = () => {
    console.log("Logging out user...");
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
    console.log("User logged out successfully");
  };
  const profile = () => {
    navigate("/pay");
  };

  return (
    <>
      {" "}
      {sidebarCollapsed && (
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          <FaArrowRight />
        </div>
      )}
      {!sidebarCollapsed && (
        <Sidebar
          collapsed={sidebarCollapsed}
          width="250px"
          className="custom-sidebar"
          backgroundColor="#1a1a1a"
        >
          <Menu iconShape="square">
            <MenuItem
              style={{
                color: "white",
                fontSize: "15px",
                fontWeight: "100",
              }}
              icon={<FaPlus />}
              onClick={handleNewDocument}
              className="menu-item menu-item-hover" // Add the hover class here
            >
              New Document
            </MenuItem>

            <div className="document-list-container" onScroll={handleScroll}>
              <Menu
                style={{ color: "white" }}
                title="Documents"
                open={true}
                icon={<FaFileAlt />}
              >
                {documents.map((doc, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleRowClick(doc._id)}
                    className={
                      doc._id === selectedDocId ? "selected-document" : ""
                    }
                  >
                    <div className="document-item-container">
                      <div className="document-title-container">
                        <span className="document-title">{doc.fileName}</span>
                      </div>
                      <div className="document-delete-icon-container">
                        <FaTrashAlt
                          className="delete-icon"
                          style={{ color: "white" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDocument(doc._id);
                          }}
                        />
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Menu>
          <div className="fixed-section"></div>
          <div className="user-profile-section">
            {(!user || !user.isPayingUser) && (
              <button className="startButton" onClick={handleStartButtonClick}>
                Upgrade ✨
              </button>
            )}
            <div className="user-icon-container">
              <Dropdown>
                <Dropdown.Toggle
                  style={{ backgroundColor: "#677de1" }}
                  id="dropdown-basic"
                >
                  <FaUser className="user-icon" style={{ color: "white" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item className="itemMenu" onClick={profile}>
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item className="itemMenu" onClick={logout}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <span className="user-name">
              {user ? user.displayName || user.email : "Loading..."}
            </span>
          </div>
        </Sidebar>
      )}
      {!sidebarCollapsed && (
        <div className="sidebar-toggle collapsed" onClick={toggleSidebar}>
          <FaArrowLeft />
        </div>
      )}
    </>
  );
};
export default SideBar;
