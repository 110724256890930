import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import axios from "axios";
import Profile from "../Profile/Profile";
import DocumentUpload from "../PDFDocument/DocumentUpload";
import DocumentDemo from "../PDFDocument/DocumentDemo";

import DocumentEdit from "../PDFDocument/DocumentEdit";
import UserContext from "../Context/UserContext";
import SubscriptionFrom from "../Payments/SubscriptionForm";
import LoginPage from "../Login/LoginPage";
import LandingPage from "../LandingPage/LandingPage";
import Navbar from "../LandingPage/Navbar";
import DocumentPage from "../DocumentView/DocumentPage";
import TermsOfService from "./TermsOfService";
import DocumentViewer from "../PDFDocument/DocumentViewer";
import PaymentSuccessBanner from "./PaymentSuccess";
import NotFoundPage from "./NotFound";
import WelcomePage from "../Pages/WelcomePage";
function Layout() {
  const [showModal, setShowModal] = useState(false);
  const [qaList, setQaList] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchQA = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions-answers`
        );
        setQaList(data);
      } catch (error) {
        console.error("Error fetching Q&A", error);
      }
    };
    fetchQA();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const ProtectedRoute = ({ children }) => {
    useEffect(() => {
      if (!user) {
        openModal();
      }
    }, [user]);

    return user ? children : null;
  };

  return (
    <div className="main-container">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />

          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path="/upload" element={<DocumentUpload />} />

          <Route path="/upload/:docId" element={<DocumentViewer />} />
          <Route path="/demo" element={<DocumentDemo />} />
          <Route path="/success" element={<PaymentSuccessBanner />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/pay" element={<SubscriptionFrom />} />

          <Route path="/document" element={<DocumentPage />} />
          <Route path="/terms" element={<TermsOfService />} />
          {/* <Route path="/subscribe" element={<PricingSection />} /> */}

          <Route
            path="/documents/:userId"
            element={
              <ProtectedRoute>
                <DocumentEdit />
              </ProtectedRoute>
            }
          />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default Layout;
