import React, { useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "./LoginPage.css";
function SignUpPage() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mode, setMode] = useState("signup");
  const [errorMessage, setErrorMessage] = useState("");

  const toggleMode = () => {
    setMode(mode === "signup" ? "login" : "signup");
    setErrorMessage("");
  };

  const handleSignUp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        { username, email, password }
      );

      if (response.status === 201) {
        console.log("Switching to login mode");
        setMode("login");
        setErrorMessage("");
      }
    } catch (error) {
      console.error("Sign up error", error.response);

      if (error.response && error.response.status === 400) {
        setErrorMessage("Email or username is already in use.");
      } else {
        setErrorMessage(
          "An error occurred during sign up. Please try again later."
        );
      }
    }
  };
  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        { email, password }
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        window.location.href = "/welcome";
      }
    } catch (error) {
      console.error("Login error", error.response);
      setErrorMessage("Incorrect email or password."); // Set error message on failed login
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (mode === "signup") {
      await handleSignUp();
    } else {
      await handleLogin();
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <Card style={{ width: "400px" }}>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <h2>{mode === "signup" ? "Sign Up" : "Log In"}</h2>
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
            {mode === "signup" && (
              <Form.Group className="mb-3" controlId="username">
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              style={{ background: "#677DE1" }}
            >
              {mode === "signup" ? "Sign Up" : "Log In"}
            </Button>
          </Form>
          <p
            className="text-center mt-3"
            style={{ cursor: "pointer" }}
            onClick={toggleMode}
          >
            {mode === "signup"
              ? "Already have an account? Log In"
              : "Don't have an account? Sign Up"}
          </p>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SignUpPage;
