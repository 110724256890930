import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./NavBar.css";
import SignUpModal from "../Login/LoginModal/SignUpModal";
import UserContext from "../Context/UserContext";
import confetti from "canvas-confetti";

const Navbar = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { user } = useContext(UserContext);
  const dropdownRef = useRef(null);
  const [canRunConfetti, setCanRunConfetti] = useState(true);

  const openModal = () => {
    setShowModal(true);
  };

  const toggleNav = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = () => {
    console.log("Logging out user...");
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
    console.log("User logged out successfully");
  };

  const startConfetti = () => {
    if (canRunConfetti) {
      confetti({
        particleCount: 200,
        spread: 70,
        origin: { y: 0.6 },
      });

      setCanRunConfetti(false);
      setTimeout(() => {
        setCanRunConfetti(true);
      }, 5000);
    }
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = document.querySelector(".navbar").offsetHeight;

      const additionalOffset = 20;
      const offsetTop =
        section.getBoundingClientRect().top + window.pageYOffset;

      const topPosition = offsetTop - navbarHeight - additionalOffset;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };
  const handleStartButtonClick = () => {
    if (user) {
      // If the user is logged in
      navigate("/pay"); // Navigate to the /pay route
    } else {
      // If no user is logged in
      openModal(); // Open the modal
    }
  };
  if (user) {
    // User is logged in, return null or a different component for logged-in users
    return null;
  }
  return (
    <>
      <nav className="navbar">
        <div className="hamburger">
          {" "}
          <button
            className="nav-toggle"
            onClick={toggleNav}
            aria-label="toggle navigation"
          >
            <i className="fas fa-bars"></i> {/* Hamburger Icon */}
          </button>{" "}
        </div>

        <ul className={`navList ${isNavCollapsed ? "collapsed" : ""}`}>
          {!user ? (
            <>
              <li
                className="navItem"
                onClick={() => {
                  setIsNavCollapsed(false);
                  scrollToSection("home");
                }}
              >
                {" "}
                <Link to="/">
                  {" "}
                  <span>Home</span>
                </Link>
              </li>
              <li
                className="navItem"
                onClick={() => {
                  setIsNavCollapsed(false);
                }}
              >
                <Link to="/demo">
                  {" "}
                  <span>Demo</span>
                </Link>
              </li>
              {/* <li
                className="navItem"
                onClick={() => {
                  setIsNavCollapsed(false);
                  scrollToSection("pricing");
                }}
              >
                <span>Pricing</span> 
              </li> */}
            </>
          ) : (
            <>
              <li
                className="navItem"
                onClick={() => setIsNavCollapsed(!isNavCollapsed)}
              >
                <Link to="/document">Documents</Link>
              </li>
              <li
                className="navItem"
                onClick={() => setIsNavCollapsed(!isNavCollapsed)}
              >
                <Link to="/upload">Upload</Link>
              </li>
              <li
                className="navItem"
                onClick={toggleDropdown}
                ref={dropdownRef}
              >
                <div>
                  <i className="fas fa-user"></i>{" "}
                </div>
                {showDropdown && (
                  <ul className="dropdownMenu">
                    <li>
                      <a href="/pay">Settings</a>
                    </li>
                    <li>
                      <a href="#" onClick={logout}>
                        Sign Out
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            </>
          )}
        </ul>
        {(!user || !user.isPayingUser) && (
          <button
            className="start-Button"
            onClick={handleStartButtonClick}
            onMouseEnter={startConfetti}
          >
            {user ? (
              <>
                Go Premium
                <span style={{ fontSize: "1.5em", marginLeft: "5px" }}>✨</span>
              </>
            ) : (
              <>
                {" "}
                Get Started
                <span style={{ fontSize: "1.5em", marginLeft: "5px" }}>
                  ✨
                </span>{" "}
              </>
            )}{" "}
          </button>
        )}
      </nav>

      <SignUpModal show={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default Navbar;
