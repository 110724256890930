import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaTimes } from "react-icons/fa";
import "./GoPremiumModal";
import { useNavigate } from "react-router-dom";

const GoPremiumModal = ({ show, onClose }) => {
  const navigate = useNavigate();

  const goToPremiumPage = () => {
    navigate("/pay");
  };
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop2">
      <div className="modal2">
        {/* <FaTimes className="close-icon" onClick={onClose} /> */}
        <h2>Go Premium Today!</h2>
        <p>Looks like you reached your upload limit!</p>

        <Button
          style={{ backgroundColor: "#677DE1" }}
          type="button"
          className="w-100"
          onClick={goToPremiumPage}
        >
          Go Premium
        </Button>
      </div>
    </div>
  );
};

export default GoPremiumModal;
