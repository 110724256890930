import React from "react";
import "./TermsOfService.css";
const TermsOfService = () => {
  return (
    <div className="centered-content">
      <section>
        <h1>Lurnify Terms of Service</h1>
        <p>Last Updated: 02/25/2024</p>
      </section>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          Welcome to Lurnify ("Lurnify," "we," "our," or "us"). These Terms of
          Service ("ToS") govern your access to and use of Lurnify's services,
          including our website, mobile applications, and any other products or
          services offered by Lurnify. By accessing or using Lurnify, you agree
          to comply with and be bound by these ToS. If you do not agree with
          these terms, please do not use Lurnify.
        </p>
      </section>

      <section>
        <h2>
          2. Video script showing how Lurnify plans to use the Google user data
          from scopes
        </h2>
        <p>
          {" "}
          Eligibility: You must be at least 13 years old to use Lurnify. By
          using our services, you represent and warrant that you meet this
          eligibility requirement.User Accounts: To access certain features of
          Lurnify, you may need to create a user account. You are responsible
          for maintaining the confidentiality of your account information and
          are liable for all activities associated with your account. Acceptable
          Use: You agree not to use Lurnify for any unlawful or unauthorized
          purposes. You shall comply with all applicable laws and regulations
          while using our services.
        </p>
      </section>

      <section>
        <h2>3. Privacy and Data</h2>
        <p>
          Privacy Policy: Your use of Lurnify is also governed by our Privacy
          Policy. Please review our Privacy Policy to understand how we collect,
          use, and protect your personal information. User Content: You are
          solely responsible for any content you upload, submit, or share on
          Lurnify. By posting content, you grant Lurnify a non-exclusive,
          worldwide, royalty-free license to use, reproduce, modify, and
          distribute your content.
        </p>
      </section>

      <section>
        <h2>4. Intellectual Property</h2>
        <p>
          Ownership: Lurnify and its licensors retain ownership of all
          intellectual property rights related to our services, including
          copyrights, trademarks, and patents. License: Lurnify grants you a
          limited, non-exclusive, non-transferable, and revocable license to
          access and use our services for personal, non-commercial purposes.
        </p>
      </section>

      <section>
        <h2>5. Termination</h2>
        <p>
          Lurnify reserves the right to terminate your access to our services at
          any time, with or without cause and with or without notice. Upon
          termination, you must cease using Lurnify and destroy all copies of
          our content in your possession.
        </p>
      </section>

      <section>
        <h2>6. Disclaimers and Limitations of Liability</h2>
        <p>
          No Warranty: Lurnify provides its services on an "as is" and "as
          available" basis, without warranties of any kind, either express or
          implied. Limitation of Liability: To the fullest extent permitted by
          law, Lurnify shall not be liable for any indirect, incidental,
          special, consequential, or punitive damages, or any loss of profits or
          revenues.
        </p>
      </section>

      <section>
        <h2>7. Governing Law</h2>
        <p>
          These ToS are governed by and construed in accordance with the laws of
          the state of Florida, without regard to its conflict of law
          principles.
        </p>
      </section>

      <section>
        <h2>8. Changes to Terms</h2>
        <p>
          Lurnify reserves the right to update or modify these ToS at any time.
          Changes will be effective upon posting on our website or notifying you
          via email. It is your responsibility to review these ToS periodically.
        </p>
      </section>

      <section>
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding these ToS, please
          contact us at support@lurnify.co
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
