// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  width: 18rem;
  cursor: pointer;
  margin-top: 0; /* Ensures there's no space at the top of the cards */
  margin-bottom: 0;
  border: 1px solid white;
}
.card:hover {
  border-color: #677de1;
}
.row.mt-n2 {
  margin-top: -0.5rem; /* Adjust the value as needed */
}
.welcome-message {
  height: auto;
}
.coming-soon-placeholder {
  width: 100%;
  height: 160px; /* Match the height of your current images */
  background-color: #f8f9fa; /* Light gray background, similar to Bootstrap's default card image placeholder */
  color: #6c757d; /* Bootstrap's default text color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Welcomepage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,aAAa,EAAE,qDAAqD;EACpE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,mBAAmB,EAAE,+BAA+B;AACtD;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,aAAa,EAAE,4CAA4C;EAC3D,yBAAyB,EAAE,iFAAiF;EAC5G,cAAc,EAAE,mCAAmC;EACnD,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".card {\n  width: 18rem;\n  cursor: pointer;\n  margin-top: 0; /* Ensures there's no space at the top of the cards */\n  margin-bottom: 0;\n  border: 1px solid white;\n}\n.card:hover {\n  border-color: #677de1;\n}\n.row.mt-n2 {\n  margin-top: -0.5rem; /* Adjust the value as needed */\n}\n.welcome-message {\n  height: auto;\n}\n.coming-soon-placeholder {\n  width: 100%;\n  height: 160px; /* Match the height of your current images */\n  background-color: #f8f9fa; /* Light gray background, similar to Bootstrap's default card image placeholder */\n  color: #6c757d; /* Bootstrap's default text color */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
