// ContentSection.js
import React, { useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import "./ContentSection.css";
import SignUpModal from "../Login/LoginModal/SignUpModal";
import { useNavigate } from "react-router-dom"; // Import useHistory
import UserImages from "./UserImages";
const ContentSection = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const demo = () => {
    navigate("/demo");
  };
  const imageUrls = [
    "./download.jpeg",
    "./testdummy2.jpeg",
    "./testdumy3.jpeg",
    "./testdummy4.jpeg",
  ];
  return (
    <>
      <div className="content">
        <div className="typewriter-section" id="demo">
          <h3>
            Talk to your{" "}
            <span style={{ fontWeight: "bold" }}>
              <Typewriter
                words={["PDFs", "Research Paper", "Instruction Manual"]}
                loop={0}
                cursor
                cursorStyle="_"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </h3>
          <p className="promo-text">
            Experience the convenience of uploading any document and asking
            questions with ease.
            <br />
            Ask questions, get summaries, find information, and more!
          </p>

          <button className="start-trial-button" onClick={openModal}>
            Get Started
          </button>
          <button className="try-demo-button" onClick={demo}>
            Try the demo →
          </button>
          {/* <div className="user-images">
            {" "}
            <UserImages imageUrls={imageUrls} />
            <p>Loved by happy users</p>{" "}
          </div> */}
        </div>
      </div>
      <div className="video-section">
        <img
          className="video-placeholder"
          src="./1_YouTube.gif"
          alt="Description of content"
        />
      </div>

      <SignUpModal show={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default ContentSection;
