import React from "react";
import Layout from "./Layout/Layout";
import { UserProvider } from "../src/Context/UserContext";
import { FileProvider } from "./Layout/FileProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import GoogleTagManager from "./GoogleTagManager";
import BingUETTag from "./BingUETTag";

function App() {
  return (
    <>
      <GoogleTagManager />
      <BingUETTag />

      <UserProvider>
        <FileProvider>
          <Layout />
        </FileProvider>
      </UserProvider>
    </>
  );
}

export default App;
