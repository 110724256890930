import React from "react";
import "./Images.css";

const UserImages = ({ imageUrls }) => {
  return (
    <div className="user-images-container">
      {imageUrls.map((url, index) => (
        <img key={index} src={url} alt="User" className="circular-image" />
      ))}
    </div>
  );
};

export default UserImages;
