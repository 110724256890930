import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QuestionPost from '../AskQuestion/PDFQuestion';
import { useParams } from 'react-router-dom';
import PDFViewer from './PDFViewer';

const DocumentViewer = () => {
  const { docId } = useParams();
  const [title, setTitle] = useState('');
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${docId}`, { withCredentials: true });
        setTitle(response.data.title);
        
        // Set the fileUrl from the response data
        setFileUrl(response.data.fileUrl);
        console.log(response.data.fileUrl);
      } catch (err) {
        console.error(err);
      }
    };
    if (docId) {
      fetchDocument();
    }
  }, [docId]);

  return (
    <div className="flexContainer">
      <h2>Document: {title}</h2>
      <div className="viewerContainer">
        <div className="pdfViewer">
          {/* Display the PDF using the PDFViewer component */}
          <PDFViewer fileUrl={fileUrl} />
        </div>
        <div className="questionPost">
          <QuestionPost documentId={docId} />
        </div>
      </div>
    </div>
  );
};

export default DocumentViewer;
