// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-image {
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  border-radius: 50%; /* This will make it circular */
  object-fit: cover; /* This will make sure the image covers the area without stretching */
  margin: 0 1px; /* Optional: Adds space between images */
}
`, "",{"version":3,"sources":["webpack://./src/LandingPage/Images.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,8BAA8B;EAC3C,YAAY,EAAE,8BAA8B;EAC5C,kBAAkB,EAAE,+BAA+B;EACnD,iBAAiB,EAAE,qEAAqE;EACxF,aAAa,EAAE,wCAAwC;AACzD","sourcesContent":[".circular-image {\n  width: 20px; /* Adjust the size as needed */\n  height: 20px; /* Adjust the size as needed */\n  border-radius: 50%; /* This will make it circular */\n  object-fit: cover; /* This will make sure the image covers the area without stretching */\n  margin: 0 1px; /* Optional: Adds space between images */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
