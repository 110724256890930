import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "../Context/UserContext";
import "./SubscriptionForm.css";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import PricingSection from "../LandingPage/PricingSection"; // Make sure to import the PricingSection component
import SideBar from "../DocumentView/SideBar";
const SubscriptionForm = () => {
  const { user, loading: userLoading } = useUser();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cancelMessage, setCancelMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user data is still loading
    if (userLoading) {
      // Maybe show a loading spinner or message
      return;
    }

    // Redirect to login page if not authenticated
    if (!user) {
      navigate("/login");
    }
  }, [user, userLoading, navigate]);

  const isSubscribed = user?.isPayingUser;
  const { subscriptionStartDate, nextBillingDate } = user || {};

  const formattedStartDate = subscriptionStartDate
    ? new Date(subscriptionStartDate).toLocaleDateString()
    : "N/A";
  const formattedNextBillingDate = nextBillingDate
    ? new Date(nextBillingDate).toLocaleDateString()
    : "N/A";

  const startTrial = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User is not authenticated");
      return;
    }

    // Decode the JWT token to extract the user's email
    const decodedToken = jwtDecode(token);
    const userEmail = decodedToken.email; // Ensure 'email' is the correct property in the token

    setLoading(true);
    setError("");

    try {
      // Send a request to your server to start the trial
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/start-trial`,
        { email: userEmail },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include JWT token in the request
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log("Response from start-trial:", response.data);

      // Handle the response and redirect to Stripe Checkout
      const stripe = window.Stripe(
        "pk_live_51K6KDPDmhaWmG42QNPxg8McMgpoYgQS6OnQDujvsduqWVsnYtZlBK3LJmZn3qWCm5fyQgOBbWRggpjRt81Ysnrrz004TnEfyh8"
      );
      const { sessionId } = response.data;
      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (stripeError) {
        setError(stripeError.message);
      }
    } catch (err) {
      console.error(
        "Error in startTrial:",
        err.response ? err.response.data : err
      );
      setError(
        err.response?.data?.error ||
          "An error occurred during the trial start process"
      );
    } finally {
      setLoading(false);
    }
  };

  if (!isSubscribed) {
    // If the user is not a paying user, render the PricingSection component
    return <PricingSection />;
  }

  // Cancel Trial
  const cancelSubscription = async () => {
    if (!user) {
      setError("User is not authenticated");
      return;
    }

    setLoading(true);
    setError("");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/cancel-subscription`,
        {},
        { withCredentials: true }
      );

      setCancelMessage(
        "Your subscription has been cancelled. You will have access until the end of your billing period."
      );
    } catch (err) {
      setError(err.message || "Error cancelling subscription");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    cancelSubscription();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    startTrial();
  };

  return (
    <>
      {" "}
      <SideBar />
      <div className="subscription-form">
        <h1 className="title">Your Subscription Details</h1>
        <p style={{ color: "black" }}>Questions? support@lurnify.co</p>
        <table className="subscription-table">
          <thead>
            <tr className="headers">
              <th>Subscription Status</th>
              <th>Start Date</th>
              <th>Next Billing Date</th>
            </tr>
          </thead>
          <tbody>
            <tr className="values">
              <td
                className={
                  user.isPayingUser ? "status-active" : "status-inactive"
                }
              >
                {user.isPayingUser ? "Active" : "Inactive"}
              </td>
              <td>{formattedStartDate}</td>
              <td>{user.isPayingUser ? formattedNextBillingDate : "N/A"}</td>
            </tr>
          </tbody>
        </table>

        <div className="form-actions">
          {!isSubscribed && (
            <form onSubmit={handleSubmit} className="trial-form">
              <button
                type="submit"
                disabled={loading || !user}
                className="trial-button"
              >
                {loading ? "Starting..." : "Go Premium"}
              </button>
            </form>
          )}
          {isSubscribed && (
            <form onSubmit={handleCancel} className="cancel-form">
              <button
                type="submit"
                disabled={loading}
                className="cancel-button"
              >
                {loading ? "Processing..." : "Cancel Subscription"}
              </button>
            </form>
          )}
        </div>

        {cancelMessage && <p className="cancel-message">{cancelMessage}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
};

export default SubscriptionForm;
