import React, { useState, useEffect } from "react";
import PDFViewerDemo from "./PDFViewerDemo";
import "./document.css";
import PDFQuestionDemo from "../AskQuestion/PDFQuestionDemo";
import GoPremiumModal from "./GoPremiumModal";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../Layout/Footer";
const DocumentUpload = () => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [showGoPremiumModal, setShowGoPremiumModal] = useState(false);

  useEffect(() => {
    fetch("/bitcoin.pdf")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const pdfFile = new File([blob], "bitcoin.pdf", {
          type: "application/pdf",
        });
        setFile(pdfFile);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  useEffect(() => {
    setFileUrl("/bitcoin.pdf");
  }, []);

  return (
    <>
      <div className="pdf-page" style={{ height: "80%", overflowY: "hidden" }}>
        <div className="content-container">
          <div className="pdf-viewer-container" style={{ marginTop: "60px" }}>
            {fileUrl ? (
              <PDFViewerDemo fileUrl={fileUrl} />
            ) : (
              <div>Loading PDF...</div>
            )}
          </div>

          {file && (
            <div
              className="question-box-container"
              style={{ marginTop: "60px" }}
            >
              <PDFQuestionDemo fileUrl={fileUrl} fileBlob={file} />
            </div>
          )}
        </div>
        <Footer />
      </div>

      <GoPremiumModal
        show={showGoPremiumModal}
        onClose={() => setShowGoPremiumModal(false)}
      />
    </>
  );
};

export default DocumentUpload;
