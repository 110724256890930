// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    background-color: #1a1a1a; /* Dark background */
    color: white; /* Light text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-family: 'Arial', sans-serif;
  }
  
  .App-header {
    background: radial-gradient(circle at center, #333 0%, #1a1a1a 100%);
    animation: breathe-animation 2s ease-in-out infinite;
    padding: 20px;
  }
  
  .top-banner {
    background: #ffcc00;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    font-size: 0.9em;
  }
  
  .content h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
  }
  
  .content p {
    margin-bottom: 1em;
  }
  
  .upload-button {
    background-color: #ffcc00; /* Yellow button */
    border: none;
    padding: 1em 1.5em;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #e6b800;
  }
  
  .supported-formats {
    margin-top: 1em;
    font-size: 0.8em;
  }
/*   
  @keyframes breathe-animation {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
  } */
  `, "",{"version":3,"sources":["webpack://./src/LandingPage/LandingPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB,EAAE,oBAAoB;IAC/C,YAAY,EAAE,eAAe;IAC7B,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,gCAAgC;EAClC;;EAEA;IACE,oEAAoE;IACpE,oDAAoD;IACpD,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,oBAAoB;EACtB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,yBAAyB,EAAE,kBAAkB;IAC7C,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;;;;KAIK","sourcesContent":[".App {\n    text-align: center;\n    background-color: #1a1a1a; /* Dark background */\n    color: white; /* Light text */\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    font-family: 'Arial', sans-serif;\n  }\n  \n  .App-header {\n    background: radial-gradient(circle at center, #333 0%, #1a1a1a 100%);\n    animation: breathe-animation 2s ease-in-out infinite;\n    padding: 20px;\n  }\n  \n  .top-banner {\n    background: #ffcc00;\n    padding: 10px;\n    position: absolute;\n    top: 0;\n    width: 100%;\n    text-align: center;\n    font-size: 0.9em;\n  }\n  \n  .content h1 {\n    font-size: 2.5em;\n    margin-bottom: 0.5em;\n  }\n  \n  .content p {\n    margin-bottom: 1em;\n  }\n  \n  .upload-button {\n    background-color: #ffcc00; /* Yellow button */\n    border: none;\n    padding: 1em 1.5em;\n    font-size: 1em;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .upload-button:hover {\n    background-color: #e6b800;\n  }\n  \n  .supported-formats {\n    margin-top: 1em;\n    font-size: 0.8em;\n  }\n/*   \n  @keyframes breathe-animation {\n    0%, 100% { transform: scale(1); }\n    50% { transform: scale(1.05); }\n  } */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
