// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to ensure it's on top */
  color: black;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px; /* Align to the top right corner */
  border: none;
  background: transparent;
  font-size: 24px;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  color: #000; /* Change as needed */
}
.close-button:hover {
  color: #666; /* Change as needed */
}
.close-icon {
  cursor: pointer;
  font-size: 24px;
}
.modal2 {
  background: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1001;
  width: 500px;
  height: auto;
}
.rpv-core__toolbar {
  background-color: #4a90e2 !important; /* Change to your desired color */
}

/* If the toolbar contains buttons, you might want to change their color or styling too */
.rpv-core__toolbar-button {
  color: #fff !important; /* Change text/icon color */
}

/* Targeting the toolbar's button on hover */
.rpv-core__toolbar-button:hover {
  background-color: #357abd !important; /* Darker shade for hover effect */
}
`, "",{"version":3,"sources":["webpack://./src/PDFDocument/PremiumModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,uCAAuC;EACtD,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW,EAAE,kCAAkC;EAC/C,YAAY;EACZ,uBAAuB;EACvB,eAAe;EACf,cAAc;EACd,UAAU;EACV,eAAe;EACf,WAAW,EAAE,qBAAqB;AACpC;AACA;EACE,WAAW,EAAE,qBAAqB;AACpC;AACA;EACE,eAAe;EACf,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,YAAY;AACd;AACA;EACE,oCAAoC,EAAE,iCAAiC;AACzE;;AAEA,yFAAyF;AACzF;EACE,sBAAsB,EAAE,2BAA2B;AACrD;;AAEA,4CAA4C;AAC5C;EACE,oCAAoC,EAAE,kCAAkC;AAC1E","sourcesContent":[".modal-backdrop2 {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* High z-index to ensure it's on top */\n  color: black;\n}\n.close-button {\n  position: absolute;\n  top: 10px;\n  right: 10px; /* Align to the top right corner */\n  border: none;\n  background: transparent;\n  font-size: 24px;\n  line-height: 1;\n  padding: 0;\n  cursor: pointer;\n  color: #000; /* Change as needed */\n}\n.close-button:hover {\n  color: #666; /* Change as needed */\n}\n.close-icon {\n  cursor: pointer;\n  font-size: 24px;\n}\n.modal2 {\n  background: white;\n  padding: 20px;\n  border-radius: 5px;\n  z-index: 1001;\n  width: 500px;\n  height: auto;\n}\n.rpv-core__toolbar {\n  background-color: #4a90e2 !important; /* Change to your desired color */\n}\n\n/* If the toolbar contains buttons, you might want to change their color or styling too */\n.rpv-core__toolbar-button {\n  color: #fff !important; /* Change text/icon color */\n}\n\n/* Targeting the toolbar's button on hover */\n.rpv-core__toolbar-button:hover {\n  background-color: #357abd !important; /* Darker shade for hover effect */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
