import React, { useState, useEffect } from "react";
import "./Testimonials.css";
import SignUpModal from "../Login/LoginModal/SignUpModal";

const Testimonials = () => {
  const [showModal, setShowModal] = useState(false);

  const loadTikTokScript = () => {
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    loadTikTokScript();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };
  return (
    <div className="testimonial-section">
      <h1 className="testimonial-title">See It In Action!</h1>
      <div className="videos-container">
        <div>
          <img
            style={{ width: "320px" }}
            src="./3 (1).gif"
            alt="Description of content"
          />
        </div>
        <div>
          <img
            style={{ width: "320px" }}
            src="./4.gif"
            alt="Description of content"
          />
        </div>
        <div>
          <img
            style={{ width: "320px" }}
            src="./5.gif"
            alt="Description of content"
          />
        </div>
        {/* <blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@lurnify.co/video/7328291016686030122"
          data-video-id="7328291016686030122"
          style={{ width: "320px" }}
        >
          <section></section>
        </blockquote> */}
        {/* <blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@lurnify.co/video/7326836649067826462"
          data-video-id="7326836649067826462"
          style={{ width: "320px" }}
        >
          <section></section>
        </blockquote>
        <blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@lurnify.co/video/7325713181974744351
          "
          data-video-id="7325713181974744351"
          style={{ width: "320px" }}
        >
          <section></section>
        </blockquote> */}
      </div>

      <div className="join-banner">
        <p>Ready to get started?</p>
        <p> Get started on your journey with document interaction today.</p>
        <button onClick={openModal}>
          {" "}
          Get Started
          <span style={{ fontSize: "1.5em", marginLeft: "5px" }}>✨</span>
        </button>
      </div>
      <SignUpModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Testimonials;
