import React, { useEffect } from "react";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import { useUser } from "../Context/UserContext";

const PaymentSuccessBanner = () => {
  let navigate = useNavigate();
  const { user, loading } = useUser();
  useEffect(() => {
    console.log("Current user info:", user);

    if (!loading && !user) {
      navigate("/login");
      return;
    }
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-11474300471/jFRHCNPHjYkZELfcr98q",
      });
    }
  }, [user, loading, navigate]);

  const handleNavigate = () => {
    navigate("/document");
  };
  const styles = {
    bannerContainer: {
      position: "relative",
      width: "100%",
      maxWidth: "500px",
      margin: "0 auto",
      padding: "20px",
      textAlign: "center",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      color: "black",
      marginTop: "100px",
    },
    content: {
      position: "relative",
      zIndex: "2",
    },
    checkMark: {
      fontSize: "50px",
      color: "green",
    },
    header: {
      margin: "10px 0",
      fontWeight: "bold",
    },
    paragraph: {
      margin: "10px 0",
    },
    button: {
      marginTop: "20px",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      backgroundColor: "#22c1c3",
      color: "#fff",
      cursor: "pointer",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.bannerContainer}>
      <Confetti width={window.innerWidth} height={window.innerHeight} />
      <div style={styles.content}>
        <div style={styles.checkMark}>✓</div>
        <h1 style={styles.header}>Payment succeeded!</h1>
        <p style={styles.paragraph}>
          Thank you for processing your most recent payment. You are now
          subscribed!
        </p>
        <button style={styles.button} onClick={handleNavigate}>
          Your documents
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessBanner;
