// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-page-container {
  display: flex;
  justify-content: center;
  position: relative; /* Set position relative for the container */
  min-height: 100vh;
  padding-top: 10vh; /* Adjust the top padding to move the card higher */
  padding: 20px;
}
.signup-card {
  width: 100%;
  max-width: 400px;
}

/* Adjust padding and margins as necessary */
.signup-card .form-group {
  margin-bottom: 15px;
}

.signup-card button {
  background-color: #677de1;
  border: none;
}

.signup-card button:hover {
  background-color: #5869bf; /* Darken button on hover */
}

/* Style for the toggle mode text */
.toggle-mode-text {
  cursor: pointer;
  color: #677de1;
  text-align: center;
  margin-top: 20px;
}

.toggle-mode-text:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/Login/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB,EAAE,4CAA4C;EAChE,iBAAiB;EACjB,iBAAiB,EAAE,mDAAmD;EACtE,aAAa;AACf;AACA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA,4CAA4C;AAC5C;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB,EAAE,2BAA2B;AACxD;;AAEA,mCAAmC;AACnC;EACE,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".signup-page-container {\n  display: flex;\n  justify-content: center;\n  position: relative; /* Set position relative for the container */\n  min-height: 100vh;\n  padding-top: 10vh; /* Adjust the top padding to move the card higher */\n  padding: 20px;\n}\n.signup-card {\n  width: 100%;\n  max-width: 400px;\n}\n\n/* Adjust padding and margins as necessary */\n.signup-card .form-group {\n  margin-bottom: 15px;\n}\n\n.signup-card button {\n  background-color: #677de1;\n  border: none;\n}\n\n.signup-card button:hover {\n  background-color: #5869bf; /* Darken button on hover */\n}\n\n/* Style for the toggle mode text */\n.toggle-mode-text {\n  cursor: pointer;\n  color: #677de1;\n  text-align: center;\n  margin-top: 20px;\n}\n\n.toggle-mode-text:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
