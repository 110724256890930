import React from "react";
import Navbar from "./Navbar";
import ContentSection from "./ContentSection";
import DemoSection from "./DemoSection";
import "./LandingPage.css";
import Testimonials from "./Testimonials";
import QA from "./Q&A";
import Footer from "../Layout/Footer";
function LandingPage() {
  return (
    <>
      <Navbar />
      <div id="home" style={{ marginTop: "30px" }}>
        <ContentSection />
      </div>
      <div id="demo2">
        <DemoSection />
      </div>

      <div id="pricing">
        <Testimonials />
      </div>
      <Footer />
    </>
  );
}

export default LandingPage;
