import React, { useState, useEffect, useContext } from "react";
import PDFViewer from "./PDFViewer";
import "./document.css";
import PDFQuestion from "../AskQuestion/PDFQuestion";
import UserContext from "../Context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { useFileContext } from "../Layout/FileProvider";
import "./PremiumModal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GoPremiumModal from "./GoPremiumModal";
import axios from "axios";
import { useUser } from "../Context/UserContext";

const DocumentUpload = () => {
  const { docId } = useParams();

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [showGoPremiumModal, setShowGoPremiumModal] = useState(false);
  // const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { file: initialFile } = useFileContext();
  const [userDocuments, setUserDocuments] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false); // New state for tracking upload success
  const { user, loading } = useUser(); // Use the useUser hook
  const [pdfBlob, setPdfBlob] = useState(null); // State for the blob

  useEffect(() => {
    // Log the user information
    console.log("Current user info:", user);

    // Redirect to login page if not authenticated
    if (!loading && !user) {
      navigate("/login"); // Replace '/login' with your login route
      return;
    }

    // Redirect non-paying users to the payment page
    // if (!loading && user && !user.isPayingUser) {
    //   navigate("/pay"); // Redirect to the payment page
    //   return;
    // }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (initialFile) {
      setFile(initialFile);
      setFileUrl(URL.createObjectURL(initialFile));
      handleUpload(initialFile);
    }
  }, [initialFile]);

  const checkDocumentCount = async () => {
    const userId = user?._id; // use the _id field from the user object
    const token = localStorage.getItem("token");

    if (!userId) {
      console.error("User ID is undefined");
      setShowGoPremiumModal(true); // Show modal or handle error as needed
      return 0;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userId}/document-count`, // Replace with your actual server URL
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Document count for user:", response.data.count);
      return response.data.count;
    } catch (error) {
      console.error("Error checking document count:", error);
      setShowGoPremiumModal(true); // Show modal or handle error as needed
      return 0;
    }
  };

  const handleUpload = async (fileToUpload) => {
    if (!fileToUpload) {
      alert("Please select a file first!");
      return;
    }

    // Check document count first for non-paying users
    if (!user?.isPayingUser) {
      const documentCount = await checkDocumentCount();
      if (documentCount >= 1) {
        // If the document count is 1 or more and user is not paying, show premium modal
        setShowGoPremiumModal(true);
        return;
      }
    }

    // User is either paying or has no documents uploaded; proceed with the upload
    const formData = new FormData();
    formData.append("pdf", fileToUpload);

    try {
      // Hardcoded API URL for testing
      // Replace with your actual server URL
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 201) {
        setIsUploaded(true);
        const blob = new Blob([fileToUpload], { type: "application/pdf" });
        setPdfBlob(blob); // Save the blob itself for other uses
        const blobUrl = URL.createObjectURL(blob);
        setFileUrl(blobUrl); // Set the file URL for viewing
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert(
        `File upload failed: ${error?.response?.data?.message || error.message}`
      );
    }
  };

  const onFileChange = async (e) => {
    const newFile = e.target.files[0];
    if (newFile && newFile.type === "application/pdf") {
      setFile(newFile);
      handleUpload(newFile);
    } else {
      alert("Please upload a valid PDF file.");
      e.target.value = null;
    }
  };

  useEffect(() => {
    if (docId) {
      const fetchDocument = async () => {
        try {
          const token = localStorage.getItem("token");
          // const apiUrl = process.env.REACT_APP_API_URL;

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${docId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          // Assuming the response contains the document data directly
          // Update the state with the fetched document data
          setFile(response.data);

          // If your document data includes a URL to the file, you can set it for the PDFViewer like this:
          setFileUrl(response.data.fileUrl); // Adjust according to your actual response structure

          // You may also handle other pieces of document data as needed
        } catch (error) {
          console.error("Error fetching document:", error);
          // Handle error - for example, you might want to navigate back or show an error message
        }
      };

      fetchDocument();
    }
  }, [docId]);

  useEffect(() => {
    const fetchUserDocuments = async () => {
      try {
        const token = localStorage.getItem("token");
        const apiUrl = process.env.REACT_APP_API_URL;

        const response = await axios.get(`${apiUrl}/my-documents`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Log the JSON response data
        // console.log("Response JSON:", response.data);

        setUserDocuments(response.data);
      } catch (error) {
        console.error("Error fetching documents:", error);
        // Handle errors here
      }
    };

    fetchUserDocuments();
    if (initialFile) {
      setFile(initialFile);
      setFileUrl(URL.createObjectURL(initialFile));
    }
  }, [initialFile, navigate]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "uploads") {
        // When uploads data changes in localStorage, check if canUpload is true
        if (!canUpload()) {
          setShowGoPremiumModal(true);
        }
      }
    };
    // const token = localStorage.getItem("token"); // Replace 'token' with the actual key where your token is stored
    // console.log("JWT Token:", token);

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const getUploadData = () => {
    const uploadData = localStorage.getItem("uploads");
    return uploadData ? JSON.parse(uploadData) : { count: 0, dates: [] };
  };

  const canUpload = () => {
    const uploadData = getUploadData();
    const uploadLimit = 2; // Limit of 2 different days
    return (
      uploadData.count < uploadLimit || uploadData.dates.length < uploadLimit
    );
  };

  return (
    <>
      <div className="pdf-page">
        {!isUploaded && (
          <div className="flexContainer">
            <div className="dropzone-style">
              <input type="file" id="file" onChange={onFileChange} required />
              <i
                className="fas fa-upload fa-3x"
                style={{ marginBottom: "10px", color: "#677de1" }}
              ></i>
              <p className="dropzone-text">
                Drag 'n' drop a PDF or click to select a PDF
              </p>
            </div>
          </div>
        )}

        {isUploaded && fileUrl && (
          <div className="content-container">
            <div className="pdf-viewer-container">
              <PDFViewer fileUrl={fileUrl} />
            </div>
            <div className="question-box-container">
              <PDFQuestion fileBlob={pdfBlob} fileUrl={fileUrl} />
            </div>
          </div>
        )}
      </div>

      <GoPremiumModal
        show={showGoPremiumModal}
        onClose={() => setShowGoPremiumModal(false)}
      />
    </>
  );
};

export default DocumentUpload;
