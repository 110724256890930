import React from "react";
import SideBar from "../DocumentView/SideBar";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import "./Welcomepage.css";
import { useUser } from "../Context/UserContext";

export default function DocumentPage() {
  const navigate = useNavigate();
  const { user } = useUser(); // Use the useUser hook

  const chatPDF = () => {
    navigate("/document");
  };
  return (
    <div className="flex-container">
      <div className="sidebar-container">
        <SideBar />
      </div>
      <div className="container-fluid p-0">
        {/* Welcome message row */}
        <div className="row">
          <div className="col-12 mt-5">
            <div className="fs-2 mb-2" style={{ color: "black" }}>
              Welcome{" "}
              <b> {user ? user.displayName || user.email : "Loading..."}</b>,
            </div>
            <div className="f6-2 mb-2" style={{ color: "black" }}>
              Select an option below to get started.
            </div>
          </div>
        </div>
        {/* Cards row */}
        <div style={{ display: "flex", gap: "2%" }}>
          <div>
            <Card
              className="card"
              style={{ maxWidth: "18rem", cursor: "pointer" }} // Use maxWidth for responsive design
              onClick={chatPDF}
            >
              <Card.Img
                variant="top"
                src="/Screen Shot 2024-02-16 at 7.25.17 PM.png"
              />
              <Card.Body>
                <Card.Title>Chat with Documents</Card.Title>
                <Card.Text>
                  Experience the convenience of uploading documents and asking
                  questions with ease. Ask questions, get summaries, find
                  information, and more!
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div>
            <Card className="card" style={{ width: "18rem" }}>
              <div className="coming-soon-placeholder">Coming Soon...</div>
              <Card.Body>
                <Card.Title>Create flashcards with AI</Card.Title>
                <Card.Text>
                  Experience the ease of transforming document content into
                  study aids. Simply upload your document, and let the AI
                  distill the key points into concise flashcards.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
