import React, { useState } from "react";
import "./PricingSection.css"; // Ensure you have the corresponding CSS file
import SignUpModal from "../Login/LoginModal/SignUpModal";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const PricingSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const startTrial = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User is not authenticated");
      return;
    }

    // Decode the JWT token to extract the user's email
    const decodedToken = jwtDecode(token);
    const userEmail = decodedToken.email; // Ensure 'email' is the correct property in the token

    setLoading(true);
    setError("");

    try {
      // Send a request to your server to start the trial
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/start-trial`,
        { email: userEmail },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include JWT token in the request
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log("Response from start-trial:", response.data);

      // Handle the response and redirect to Stripe Checkout
      const stripe = window.Stripe(
        "pk_live_51K6KDPDmhaWmG42QNPxg8McMgpoYgQS6OnQDujvsduqWVsnYtZlBK3LJmZn3qWCm5fyQgOBbWRggpjRt81Ysnrrz004TnEfyh8"
      );
      const { sessionId } = response.data;
      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (stripeError) {
        setError(stripeError.message);
      }
    } catch (err) {
      console.error(
        "Error in startTrial:",
        err.response ? err.response.data : err
      );
      setError(
        err.response?.data?.error ||
          "An error occurred during the trial start process"
      );
    } finally {
      setLoading(false);
    }
  };

  const startLifeTime = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User is not authenticated");
      return;
    }

    // Decode the JWT token to extract the user's email
    const decodedToken = jwtDecode(token);
    const userEmail = decodedToken.email; // Ensure 'email' is the correct property in the token

    setLoading(true);
    setError("");

    try {
      // Send a request to your server to start the trial
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lifetime`,
        { email: userEmail },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include JWT token in the request
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log("Response from start lifetime:", response.data);

      // Handle the response and redirect to Stripe Checkout
      const stripe = window.Stripe(
        "pk_live_51K6KDPDmhaWmG42QNPxg8McMgpoYgQS6OnQDujvsduqWVsnYtZlBK3LJmZn3qWCm5fyQgOBbWRggpjRt81Ysnrrz004TnEfyh8"
      );
      const { sessionId } = response.data;
      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (stripeError) {
        setError(stripeError.message);
      }
    } catch (err) {
      console.error(
        "Error in lifetime:",
        err.response ? err.response.data : err
      );
      setError(
        err.response?.data?.error ||
          "An error occurred during the life time start process"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    startTrial();
  };

  const handleLifeTime = (event) => {
    event.preventDefault();
    startLifeTime();
  };

  return (
    <div className="pricing-section">
      <h2>One Step Away From Full Access!</h2>
      <p style={{ textAlign: "center" }}>
        Ask questions, get summaries, find information, and more! <br />
        Supported formats PDF, .PPTX, .DOCX
      </p>

      <div className="pricing-tables">
        {/* <div className="pricing-table free">
          <h3>Free</h3>
          <p className="description">The basic version for individuals</p>
          <div className="price">
            $0<span className="per-month">/ Month</span>
          </div>
          <ul className="features">
            <li>Interact with one PDF Document</li>
            <li>Unlimited question prompts</li>
            <li>Email-based customer support for assistance and queries</li>
            <li>
              <b>Ideal for:</b> Individuals or small businesses with basic PDF
              document interaction needs or for those looking to try Lurnify's
              features without commitment.
            </li>
          </ul>
        </div> */}

        <div className="pricing-table paid">
          <h3>Full Access</h3>
          <p className="description">
            The comprehensive plan for individuals and businesses
          </p>
          <div className="price">
            $4.99<span className="per-month">/ Month</span>
          </div>
          <ul className="features">
            <li>Unlimited document uploads for extensive use</li>
            <li>Unlimited question prompts for in-depth interactions</li>
            <li>
              Priority email support for quick and effective issue resolution
            </li>
            <li>
              <b>Ideal for:</b> Perfect for individuals and businesses needing
              advanced document interaction capabilities for frequent and
              complex tasks. Cancel Anytime!
            </li>
          </ul>
          <button className="buy-now" onClick={handleSubmit}>
            Subscribe
          </button>
        </div>
        <div className="pricing-table paid">
          <div class="ribbon-wrapper">
            <div
              class="ribbon"
              style={{ backgroundColor: "red", color: "white" }}
            >
              Deal Ending Soon!
            </div>
          </div>
          <h3>LifeTime Access</h3>

          <p className="description">
            The comprehensive plan for individuals and businesses
          </p>
          <div className="price">
            $10<span className="per-month"></span>
          </div>
          <ul className="features">
            <li>
              Text Summarization: Generate concise summaries of intricate and
              lengthy content.
            </li>
            <li>
              Data Extraction and Analysis: Retrieve precise data, figures, and
              tables for in-depth analysis.{" "}
            </li>

            <li>
              <b>Ideal for: </b> Perfect for individuals and businesses needing
              advanced document interaction capabilities for lifetime access,
              enabling you to perform frequent and complex tasks indefinitely.
              Enjoy the benefits with a one-time payment! No recurring charges,
              ever!
            </li>
          </ul>
          <button className="buy-now" onClick={handleLifeTime}>
            Subscribe
          </button>
        </div>
      </div>
      <SignUpModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default PricingSection;
