import React from 'react'
import Userinfo from './Userinfo'
import Testprofile from './testprofile'
import QuestionPost from '../AskQuestion/PDFQuestion'
export default function Profile() {
  return (<> 
    <QuestionPost />
    <Userinfo />
    <Testprofile />
    </>
  )
}
