import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";
import { Link } from "react-router-dom"; // Import Link component from react-router-dom
import "./Router.css";
export default function App() {
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-left footer-container"
    >
      <div className="text-center p-3" style={{ backgroundColor: "#1a1a1a" }}>
        &copy; {new Date().getFullYear()} Copyright:{" "}
        <p className="text-white" style={{ marginBottom: "0" }}>
          support@lurnify.co
        </p>{" "}
        <Link to="/terms" className="text-white" style={{ marginTop: "0" }}>
          Legal
        </Link>
      </div>
    </MDBFooter>
  );
}
